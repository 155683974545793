import axios from 'axios'
import { useNavigate } from "react-router-dom";
import {apiurl} from '../src/constant'
const axiosInstance = axios.create({
  //baseURL: 'https://watapi.rajdhanitec.com/api/',
  //baseURL :'https://localhost:7254/api/'
  //baseURL :'http://localhost:5031/api/'
  //baseURL :'https://api.sipsindia.org/api/'
  baseURL : apiurl
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Modify the request config here (e.g., add headers, authentication tokens)
    const accessToken = localStorage.getItem("token");
    // ** If token is present add it to request's Authorization Header
    if (accessToken) {
      //if (config.headers) config.headers.token = accessToken;
      if (config.headers) config.headers['Authorization'] = 'Bearer ' + accessToken;
    }
    config.withCredentials=false;
    config.headers["Access-Control-Allow-Origin"]= "*";
    // config.headers["access-control-allow-methods"]= "GET,PUT,POST,DELETE,OPTIONS";
    // //config.headers["access-control-allow-origin"]="http://localhost:3000";
    //config.crossorigin=true;
    //config.mode='no-cors';
    // console.log('config');
    //config.headers["email"]= "devmca3@gmail.com";
    //console.log(config);
    return config;
  },
  (error) => {
    // Handle request errors here

    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Modify the response data here (e.g., parse, transform)
    //console.log(response);
    return response;
  },
  (error) => {
    
    //console.log('error');
    //console.log(error);
    // Handle response errors here
    if (error.response.status === 401) {
        //console.log('401'+error);
        //router.push('/login')
        //let navigate = useNavigate();
        //navigate('~/login');
        return Promise.reject(error)
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;