import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Field, Form, Formik, useFormik } from 'formik';
import { Container, Box, Typography, TextField, Button, CssBaseline, FormControl, InputLabel, Select, MenuItem, Grid, FormControlLabel, Checkbox, Tab, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { ArrowBack, Block, CheckBox } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import axiosInstance from '../../axiosInstance';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import uploadfile_service from '../../services/uploadfile_service';
export default function AddSubjectPoint(){
  const defaultTheme = createTheme();
  let { id} = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState('1');
  const [tabDisabled,settabDisabled] =useState(true);
  const [IsActive, setIsActive] = useState(false);
  const [Status, setStatus] = useState(false);
  const [Deleted, setDeleted] = useState(false);
  const [selectedSubjectPointBaseImageSM, setselectedSubjectPointBaseImageSM] = useState(null);
  const [selectedSubjectPointImageSM, setselectedSubjectPointImageSM] = useState(null);
  const [selectedSubjectPointBaseImageLG, setselectedSubjectPointBaseImageLG] = useState(null);
  const [selectedSubjectPointImageLG, setselectedSubjectPointImageLG] = useState(null);
  const [ImageIDSM,setImageIDSM]=useState(0);
  const [ImageIDLG,setImageIDLG]=useState(0);
  const [CourseList,setCourseList]=useState([]);
  const [CourseID, setCourseID] = useState(0);
  const [SubjectList,setSubjectList]=useState([]);
  const [SubjectID, setSubjectID] = useState(0);
  async function displayImageByNameLG(
    filename
  ) {
    var date = new Date();
    axiosInstance.get('File/GetImagebase64/?ndate='+ date.toDateString()+'&filename='+ filename).then( (response)=>{
      setselectedSubjectPointBaseImageLG(response.data);
     });
  };
  async function displayImageByNameSM(
    filename
  ) {
    var date = new Date();
    axiosInstance.get('File/GetImagebase64/?ndate='+ date.toDateString()+'&filename='+ filename).then( (response)=>{
      setselectedSubjectPointBaseImageSM(response.data);
     });
  };
  useEffect((event)=>{
    axiosInstance.get('Course/GetAllCourseList').then((response)=>{
        console.log(response.data);
        setCourseList(response.data);
       });
    console.log("id");
    console.log(id);
    if(id != undefined || id != null){
        settabDisabled(false);
        axiosInstance.get('SubjectPoint/GetSubjectPointMaster/' + id).then((response)=>{
            //console.log(response.data);
            //console.log(response.data.Data[0]);
            var ndata=response.data.Data[0];
            console.log('ndata');
            console.log(ndata);
            addform.values.SubjectPointName=ndata.SubjectPointName;
            addform.values.SubjectId=ndata.SubjectID;
            addform.values.SubjectPointDescription=ndata.SubjectPointDescription;
            addform.values.SubjectPointImageIdlg=ndata.SubjectPointImageIDLG;
            addform.values.SubjectPointImageIdsm=ndata.SubjectPointImageIDSM;
            addform.values.SubjectPointVideoUrl=ndata.SubjectPointVideoUrl;
            addform.values.IsActive=ndata.IsActive;
            addform.values.OrderNo=ndata.OrderNo;
            addform.values.CourseId=ndata.CourseID;
            
            setIsActive(ndata.IsActive);
            setImageIDLG(ndata.SubjectPointImageIDLG);
            setImageIDSM(ndata.SubjectPointImageIDSM);
            setCourseID(ndata.CourseID);
            getSubjectList(ndata.CourseID);
            setSubjectID(ndata.SubjectID);
            if(ndata.ImageNameLG!=null){
                displayImageByNameLG(ndata.ImageNameLG);
            }
            if(ndata.ImageNameSM!=null){
                displayImageByNameSM(ndata.ImageNameSM);
            }
           });
    }
  },[]);

  const addform = useFormik({
    initialValues: {
        CourseId:'',
        SubjectId: '',
        SubjectPointName: '',
        SubjectPointDescription: '',
        SubjectPointImageIdlg: 0,
        SubjectPointImageIdsm: 0,
        SubjectPointVideoUrl: '',
        IsActive: true,
        OrderNo: '',
    },
    validationSchema: Yup.object({
        CourseId: Yup.string().required('Course is required'),
        SubjectPointName: Yup.string().required('Subject Point is required'),
        SubjectId: Yup.string().required('Subject is required'),
        SubjectPointDescription: Yup.string().required('Subject Point Description is required'),
        OrderNo: Yup.string().required('OrderNo is required'),
    }),
    onSubmit: (values) => {
        console.log(values)
        if(id===undefined || id ===null){
            axiosInstance.post('SubjectPoint/AddSubjectPointMaster',{
                "SubjectPointId": 0,
                "SubjectPointName": values.SubjectPointName,
                "SubjectId": values.SubjectId,
                "SubjectPointDescription": values.SubjectPointDescription,
                "SubjectPointImageIdlg": values.SubjectPointImageIdlg,
                "SubjectPointImageIdsm":values.SubjectPointImageIdsm,
                "SubjectPointVideoUrl":values.SubjectPointVideoUrl,
                "OrderNo":values.OrderNo,
                "IsActive":values.IsActive
            },
            ).then((response)=>{
                //getVideoList();
                console.log(response.data);
                alert("Added SubjectPoint successfully")
            });
        }
        else{
            axiosInstance.post('SubjectPoint/UpdateSubjectPointMaster',{
                "SubjectPointId": parseInt(id),
                "SubjectPointName": values.SubjectPointName,
                "SubjectId": values.SubjectId,
                "SubjectPointDescription": values.SubjectPointDescription,
                "SubjectPointImageIdlg": values.SubjectPointImageIdlg,
                "SubjectPointImageIdsm":values.SubjectPointImageIdsm,
                "SubjectPointVideoUrl":values.SubjectPointVideoUrl,
                "OrderNo":values.OrderNo,
                "IsActive":values.IsActive
            },
            ).then((response)=>{
                //getVideoList();
                console.log(response.data);
                alert("Updated SubjectPoint successfully")
                
            });
        }
       
    }
  });
  const routeChange = () =>{ 
    navigate('/admin/SubjectPointlist');
  }
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  }; 
  function getSubjectList(courseid){
    axiosInstance.get('Subject/GetSubjectListByCourseID/?courseid=' + courseid).then((response)=>{
        console.log(response.data);
        setSubjectList(response.data);
       });
  }
  const handleIsActiveChange = (event) => {
    setIsActive(IsActive?false:true)
    addform.values.IsActive=IsActive?false:true;
  };
  const handleCourseIDChange = (event) => {
    console.log(event.target.value);
    setCourseID(event.target.value);
    getSubjectList(event.target.value);
    addform.values.CourseId=event.target.value;
  };
  const handleSubjectIDChange = (event) => {
    console.log(event.target.value);
    setSubjectID(event.target.value);
    addform.values.SubjectId=event.target.value;
  };
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = reject
    })
  };
  
  const UploadSubjectPointImageLG = event => {
    console.log('id:' + id);
    console.log('imageidlg:' + ImageIDLG);
    uploadfile_service.UploadSubjectPointImageType(selectedSubjectPointImageLG,id,ImageIDLG,2).then((data)=>{
      //alert(data.data.Message)
      alert("Record saved successfull");
      console.log(data);
    }).catch((error)=>{
      alert("Failed");
      console.log(error);
    })
  };
  const UploadSubjectPointImageSM = event => {
    console.log('id:' + id);
    console.log('imageidsm:' + ImageIDSM);
    uploadfile_service.UploadSubjectPointImageType(selectedSubjectPointImageSM,id,ImageIDSM,1).then((data)=>{
      //alert(data.data.Message)
      alert("Record saved successfull");
      console.log(data);
    }).catch((error)=>{
      alert("Failed");
      console.log(error);
    })
  };
  
    return(
        <ThemeProvider theme={defaultTheme}>
        <Container component="main">
          <CssBaseline />
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
               <Grid item xs={6}>
                  <Button 
                    style={{width:"150px"}}
                    startIcon={<ArrowBack />} onClick={routeChange}>
                    Back
                  </Button>
                </Grid>
                <Grid item xs={6}>
                    <Typography component="h6" variant="h6">
                      Add Subject Point
                    </Typography>
                </Grid>
          </Grid>
          
          
            <div style={{textAlign:'left'}}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                    <Tab label="Subject Point detail" value="1" />
                    <Tab label="Image" value="2" disabled={tabDisabled}/>
                  </TabList>
                </Box>
                <TabPanel value="1">
                <Box
                    component="form"
                    // sx={{
                    //   '& .MuiTextField-root': { m: 1, width: '35ch' },
                    // }}
                    onSubmit={addform.handleSubmit}
                  >
                  <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                <Grid item xs={6} sm={6} md={6}>
                                <FormControl fullWidth>
                                        <InputLabel id="lCourseId">Course</InputLabel>
                                        <Select
                                        labelId="lCourseId"
                                        id="CourseId"
                                        value={CourseID}
                                        label="Course"
                                        onChange={handleCourseIDChange}
                                        onBlur={addform.handleBlur}
                                        error={addform.errors.CourseId && addform.touched.CourseId ?true:false}
                                        >
                                        {CourseList.map((course,index) => (
                                            <MenuItem
                                            value={course.CourseId}
                                            key={`course_courseid${index}`}
                                            >
                                            <em>{course.CourseName}</em>
                                            </MenuItem>
                                        ))}
                                        
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                <FormControl fullWidth>
                                        <InputLabel id="lSubjectId">Subject</InputLabel>
                                        <Select
                                        labelId="lSubjectId"
                                        id="SubjectId"
                                        value={SubjectID}
                                        label="Subject"
                                        onChange={handleSubjectIDChange}
                                        onBlur={addform.handleBlur}
                                        error={addform.errors.SubjectId && addform.touched.SubjectId ?true:false}
                                        //helperText={addform.errors.SubjectId && addform.touched.SubjectId ?addform.errors.SubjectId:null}
                                        >
                                        {SubjectList.map((subject,index) => (
                                            <MenuItem
                                            value={subject.SubjectId}
                                            key={`Subject_Subjectid${index}`}
                                            >
                                            <em>{subject.SubjectName}</em>
                                            </MenuItem>
                                        ))}
                                        
                                        </Select>
                                    </FormControl>
                                </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                              <TextField
                                fullWidth
                                type="text"
                                autoComplete="off"
                                name="SubjectPointName"
                                id="SubjectPointName"
                                placeholder="Subject Point"
                                value={addform.values.SubjectPointName}
                                onChange={addform.handleChange}
                                onBlur={addform.handleBlur}
                                error={addform.errors.SubjectPointName && addform.touched.SubjectPointName ?true:false}
                                helperText={addform.errors.SubjectPointName && addform.touched.SubjectPointName ?addform.errors.SubjectPointName:null}
                              />
                              
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                              <TextField
                                fullWidth
                                type="text"
                                autoComplete="off"
                                name="SubjectPointDescription"
                                id="SubjectPointDescription"
                                placeholder="Subject Point Description "
                                value={addform.values.SubjectPointDescription}
                                onChange={addform.handleChange}
                                onBlur={addform.handleBlur}
                                error={addform.errors.SubjectPointDescription && addform.touched.SubjectPointDescription ?true:false}
                                helperText={addform.errors.SubjectPointDescription && addform.touched.SubjectPointDescription ?addform.errors.SubjectPointDescription:null}
                              />
                              
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                              <TextField
                                fullWidth
                                type="text"
                                autoComplete="off"
                                name="SubjectPointVideoUrl"
                                id="SubjectPointVideoUrl"
                                placeholder="Subject Point VideoUrl"
                                value={addform.values.SubjectPointVideoUrl}
                                onChange={addform.handleChange}
                                onBlur={addform.handleBlur}
                                error={addform.errors.SubjectPointVideoUrl && addform.touched.SubjectPointVideoUrl ?true:false}
                                helperText={addform.errors.SubjectPointVideoUrl && addform.touched.SubjectPointVideoUrl ?addform.errors.SubjectPointVideoUrl:null}
                              />
                              
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                              <TextField
                                fullWidth
                                type="number"
                                autoComplete="off"
                                name="OrderNo"
                                id="OrderNo"
                                placeholder="Order No"
                                value={addform.values.OrderNo}
                                onChange={addform.handleChange}
                                onBlur={addform.handleBlur}
                                error={addform.errors.OrderNo && addform.touched.OrderNo ?true:false}
                                helperText={addform.errors.OrderNo && addform.touched.OrderNo ?addform.errors.OrderNo:null}
                              />
                              
                      </Grid>
                      
                      
                      <Grid item xs={2} sm={4} md={4} style={{textAlign:'left',paddingLeft:'15px'}}>
                          
                          <FormControlLabel control={
                            <Checkbox
                            value={addform.values.IsActive}
                            checked={addform.values.IsActive}
                            onChange={handleIsActiveChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                          } name='IsActive'
                          label="IsActive"
                          labelPlacement='end'>,

                          </FormControlLabel>
                          
                      </Grid>
                      
                      <Grid item xs={2} sm={4} md={4}>
                        
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        
                      </Grid>
                  </Grid>
                  <br></br>
                  <Button
                    type="submit"
                    variant="contained"
                    //sx={{ mt: 1, mb: 1 ,m:1}}
                    style={{width:"150px"}}
                  >
                    Save 
                  </Button>
                  </Box>
                  
                </TabPanel>
                
                <TabPanel value="2">
                           <p>Large Image</p>
                            <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                <Grid item xs={2} sm={4} md={6}>
                                    {selectedSubjectPointBaseImageLG && (
                                        <div>
                                        <img
                                            alt="not found"
                                            width={"250px"}
                                            //src={URL.createObjectURL(selectedCategoryImage)}
                                            src={selectedSubjectPointBaseImageLG}
                                        />
                                        <br />
                                        <Button variant="contained" sx={{ mt: 1, mb: 1 ,m:1}} onClick={() => setselectedSubjectPointBaseImageLG(null)}>Remove</Button>
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={2} sm={4} md={6}>
                                    <input
                                        type="file"
                                        name="mySubjectPointImageLG"
                                        variant="contained"
                                        onChange={(event) => {
                                        
                                        getBase64(event.target.files[0]).then((result)=>{
                                            setselectedSubjectPointBaseImageLG(result);
                                        });
                                        setselectedSubjectPointImageLG(event.target.files[0]);
                                        console.log(event.target.files[0]);
                                        }}
                                    />
                                    
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 1}}
                                        style={{width:"150px"}}
                                        onClick={UploadSubjectPointImageLG}
                                    >
                                        Upload 
                                    </Button>
                                </Grid>
                            </Grid>
                            <p>Small Image</p>
                            <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                <Grid item xs={2} sm={4} md={6}>
                                    {selectedSubjectPointBaseImageSM && (
                                        <div>
                                        <img
                                            alt="not found"
                                            width={"250px"}
                                            //src={URL.createObjectURL(selectedCategoryImage)}
                                            src={selectedSubjectPointBaseImageSM}
                                        />
                                        <br />
                                        <Button variant="contained" sx={{ mt: 1, mb: 1 ,m:1}} onClick={() => setselectedSubjectPointBaseImageSM(null)}>Remove</Button>
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={2} sm={4} md={6}>
                                    <input
                                        type="file"
                                        name="mySubjectPointImageSM"
                                        variant="contained"
                                        onChange={(event) => {
                                        
                                        getBase64(event.target.files[0]).then((result)=>{
                                            setselectedSubjectPointBaseImageSM(result);
                                        });
                                        setselectedSubjectPointImageSM(event.target.files[0]);
                                        console.log(event.target.files[0]);
                                        }}
                                    />
                                    
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 1}}
                                        style={{width:"150px"}}
                                        onClick={UploadSubjectPointImageSM}
                                    >
                                        Upload 
                                    </Button>
                                </Grid>
                            </Grid>
                </TabPanel>
              </TabContext>
            </Box>       
                       
            </div>
           <div>
           </div>
        </Container>
      </ThemeProvider>
    );
}